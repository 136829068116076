<template>
  <div class="table" 
    v-loading="loading"
    element-loading-text="提交中"
    element-loading-spinner="el-icon-loading">
    <el-form ref="applyForm" :model="applyData" label-width="80px" :rules="rules" >
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="applyData.name" size="medium" placeholder="请输入项目名称"></el-input>
      </el-form-item>
      <el-form-item label="主体性质" prop="property">
        <el-select v-model="applyData.property" placeholder="请选择主体性质" size="medium">
          <el-option label="公司" value="公司"></el-option>
          <el-option label="团队" value="团队"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公司名称" prop="companyName">
        <el-input v-model="applyData.companyName" size="medium" placeholder="请输入公司/团队名称"></el-input>
      </el-form-item>
      <el-form-item label="注册时间" prop="time">
        <!-- <el-input v-model="applyData.time" size="medium" placeholder="请输入注册时间"></el-input> -->
        <el-date-picker
          v-model="applyData.time"
          type="date"
          format="YYYY-MM-DD"
          @change="conversionTime"
          placeholder="选择注册时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="注册地址" prop="addr">
        <el-input v-model="applyData.addr" size="medium" placeholder="请输入注册地址"></el-input>
      </el-form-item>
      <el-form-item label="公司法人" prop="director">
        <el-input v-model="applyData.director" size="medium" placeholder="请输入公司法人/团队负责人"></el-input>
      </el-form-item>
      <el-form-item prop="education">
        <template #label>
          <span class="text_align">学历</span>
        </template>
        <el-input v-model="applyData.education" size="medium" placeholder="请输入学历"></el-input>
      </el-form-item>
      <el-form-item label="毕业学校" prop="school">
        <el-input v-model="applyData.school" size="medium" placeholder="请输入毕业学校"></el-input>
      </el-form-item>
      <el-form-item prop="major">
        <template #label>
          <span class="text_align">专业</span>
        </template>
        <el-input v-model="applyData.major" size="medium" placeholder="请输入专业"></el-input>
      </el-form-item>
      <el-form-item label="经营范围" prop="range">
        <el-input v-model="applyData.range" size="medium" placeholder="请输入经营范围（产品/服务）"></el-input>
      </el-form-item>
      <el-form-item label="启动资金" prop="capital">
        <el-input v-model.number="applyData.capital" size="medium" placeholder="请输入启动资金/注册资金"><template slot="append">万元</template></el-input>
      </el-form-item>
      <el-form-item label="项目行业" prop="industry">
        <el-select v-model="applyData.industry" placeholder="请选择项目行业" size="medium">
          <template v-for="(item, index) in industryList">
            <el-option :label="item" :value="item"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item prop="projectDirector">
        <template #label>
          <span  class="text_align">负责人</span>
        </template>
        <el-input v-model="applyData.projectDirector" size="medium" placeholder="请输入项目负责人"></el-input>
      </el-form-item>
      <el-form-item prop="phone">
        <template #label>
          <span class="text_align">手机号</span>
        </template>
        <el-input v-model="applyData.phone" size="medium" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="运营状态" prop="state">
        <el-select v-model="applyData.state" placeholder="请选择运营状态" size="medium">
          <template v-for="(item, index) in stateList">
            <el-option :label="item" :value="item"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="项目亮点" class="prospect" prop="prospect">
        <el-input type="textarea" v-model="applyData.prospect" rows="4" resize="none" maxlength="300" show-word-limit placeholder="请输入项目亮点/发展前景"></el-input>
      </el-form-item>
      <el-form-item label="项目阶段" prop="stage">
        <el-select v-model="applyData.stage" placeholder="请选择项目阶段" size="medium">
          <template v-for="(item, index) in stageList">
            <el-option :label="item" :value="item" ></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item prop="turnover">
        <template #label>
          <span class="text_align">营业额</span>
        </template>
        <el-input v-model.number="applyData.turnover" size="medium" placeholder="项目营业额"><template slot="append">万元</template></el-input>
      </el-form-item>
      <el-form-item label="项目所获成果/奖项/立项/投资等（须附证明材料）" class="award">
        <el-input type="textarea" v-model="applyData.award" rows="4" resize="none" placeholder="请输入项目所获成果/奖项/立项/投资等（须附证明材料如果没有可不提供）"></el-input>
        <div class="upload_img">
          <el-upload
            class="upload-demo"
            :action="domain + '//ly.36yx.com/api.php?m=njkj&appkey=1507eaa915f06e9984905fc09d63315f&a=upload'"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :on-error="handleError"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.svg"
            :show-file-list="false">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item label="申请工位/面积" class="area" prop="station">
        <div class="outside">
          <div class="within">
            <div class="commonFlex">
              <span class="title">工位：</span><el-input v-model.number="applyData.station" size="medium"><template slot="append">个</template></el-input>
            </div>
            <div class="commonFlex">
              <span class="title">面积：</span><el-input v-model.number="applyData.area" size="medium"><template slot="append">平方</template></el-input>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="其他申请">
        <el-input type="textarea" v-model="applyData.other" rows="4" resize="none" placeholder="请输入其他申请"></el-input>
      </el-form-item>
      <el-form-item class="operation">
        <el-button type="primary" @click="onSubmit" :disabled="disable">提交</el-button>
        <el-button @click="onReset" :disabled="disable">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import * as imageConversion from 'image-conversion'
export default {
  data() {
    return {
      value2: "",
      disable: false,
      loading: false,
      applyData: {
        name: "",
        property : "",
        companyName: "",
        time: "",
        addr: "",
        director: "",
        education: "",
        school: "",
        major: "",
        range: "",
        capital: "",
        industry: "",
        projectDirector: "",
        phone: "",
        state: "",
        prospect: "",
        stage: "",
        turnover: "",
        award: "",
        station: "",
        area: "",
        other: ""
      },
      domain: document.location.protocol,
      imgUrl: "",
      industryList: ["移动互联网","电子信息","软件开发","互联网游戏","互联网技术","文化传媒","O2O","智能硬件"],
      stateList: ["初创时期","市场扩展期","已经盈利"],
      stageList: ["计划阶段","开发中","已形成产品"],
      rules: {
        name: [{ required: true, message: "项目名称不能为空", trigger: "blur" }],
        property: [{ required: true, message: "主体性质不能为空", trigger: "change" }],
        companyName: [{ required: true, message: "公司/团队名称不能为空", trigger: "blur" }],
        time: [{ required: true, message: "注册时间不能为空", trigger: "change" }],
        addr: [{ required: true, message: "注册地址不能为空", trigger: "blur" }],
        director: [{ required: true, message: "公司法人/团队负责人不能为空", trigger: "blur" }],
        education: [{ required: true, message: "学历不能为空", trigger: "blur" }],
        school: [{ required: true, message: "毕业学校不能为空", trigger: "blur" }],
        major: [{ required: true, message: "专业不能为空", trigger: "blur" }],
        range: [{ required: true, message: "经营范围（产品/服务）不能为空", trigger: "blur" }],
        capital: [{ required: true, message: "启动资金/注册资金不能为空", trigger: "blur" },
         { type: 'number', message: '启动资金/注册资金必须为数字值', trigger: "blur" }],
        industry: [{ required: true, message: "项目行业不能为空", trigger: "change" }],
        projectDirector: [{ required: true, message: "项目负责人不能为空", trigger: "blur" }],
        phone: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
        state: [{ required: true, message: "运营状态不能为空", trigger: "change" }],
        prospect: [{ required: true, message: "项目亮点/发展前景不能为空", trigger: "blur" },
        { min: 1, max: 300, message: '项目亮点/发展前景长度在1到300个字符', trigger: 'blur' }],
        stage: [{ required: true, message: "项目阶段不能为空", trigger: "change" }],
        turnover: [{ required: true, message: "项目营业额不能为空", trigger: "blur" },
        { type: 'number', message: '项目营业额必须为数字值', trigger: "blur" }],
        station: [{ required: true, message: "工位数不能为空", trigger: "blur" },
        { type: 'number', message: '工位数必须为数字值', trigger: "blur" }]
      }
    }
  },
  methods: {
    conversionTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1 < 10? "0" + (date.getMonth() + 1): date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      this.applyData.time = y+ '-' + m + '-' + d;
    },

    beforeUpload (file) { 
      return new Promise((resolve, reject) => {
        let isLt2M = file.size / 1024 / 1024 < 2 // 判定图片大小是否小于2MB
        if (isLt2M) {
          resolve(file);
        }else {
          imageConversion.compressAccurately(file, 400).then(res => { // 
            resolve(res);
          });
        }
      });
    },

    handleSuccess(res, file, fileList) {
      this.getToken("上传成功！", "success");
      this.imgUrl = res.src;
    },

    handleError(err, file, fileList) {
      this.getToken("上传失败！", "error");
    },

    onSubmit() {
      if(this.applyData.imgUrl == null) {
        Reflect.set(this.applyData, "imgUrl", this.imgUrl);
      }else {
        this.applyData.imgUrl = this.imgUrl;
      }
      this.disable = true;
      this.$refs["applyForm"].validate((valid) => {
        if(valid) {
          this.loading = true;
          this.$axios.post(document.location.protocol + "//ly.36yx.com/api.php?m=njkj&appkey=1507eaa915f06e9984905fc09d63315f&a=join", this.applyData).then(res => {
            if(res != null) {
              if(res.data.code == 0) {
                this.getToken(res.data.msg, "success");
                this.getLoading();
              }else {
                this.getLoading();
              }
            }else {
              this.getLoading();
            }
          }).catch(e => {
            this.anomaly(); //全局异常消息提示
            this.getLoading();
          });
        }else {
          this.getProhibit();
        }
      });
    },

    getLoading() {
      setTimeout(() => {
        this.loading = false;
        this.disable = false;
      }, 1500);
    },

    onReset() {
      this.applyData = this.$options.data().applyData;
    }
  }
}
</script>

<style scoped>
.table {
  padding: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.table .el-select {
  width: 100%;
}

.table .upload_img {
  margin-top: 10px;
}

.table .outside {
  display: inline-block;
}

.table .outside .within {
  display: flex;
  justify-content: space-between;
}

.table .outside .within .commonFlex {
  display: flex;
  align-items: center;
}

.table .outside .within .commonFlex .title {
  width: 42px;
}

.table .outside .within .commonFlex .el-input {
  width: calc(100% - 42px);
}

.table .outside .within .commonFlex:nth-child(1) {
  width: 45%;
}

.table .outside .within .commonFlex:nth-child(2) {
  width: 50%;
}

.table .text_align {
  text-align: justify;
  text-justify: distribute;
  word-break: break-all;
  -moz-text-align-last: justify;
  text-align-last: justify; 
  width: 56px;
  display: block;
  float: right;
}


@media screen and (max-width: 340px) {

  .outside .within {
    display: block !important;
    justify-content: normal !important;
  }

  .outside  .within .commonFlex:nth-child(1) {
    width: 100% !important;
  }

  .outside  .within .commonFlex:nth-child(2) {
    width: 100% !important;
  }

}
</style>

<style>
.table .prospect .el-form-item__content {
  line-height: 16px !important;
}

.table .award label,
.table .area label {
  width: auto !important;
  padding: 0 !important;
}

.table .award .el-form-item__content,
.table .area .el-form-item__content {
  margin-left: 0 !important;
}

.table .operation {
  margin-bottom: 0 !important;
}

.table .operation .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.el-date-editor.el-input {
  width: 100% !important;
}
</style>